// Normalize Styles
@import 'node_modules/normalize.css/normalize';

// Import Modules
// @import '../_modules/link/link';

// Box sizing partial styles:
// Apply a natural box layout model to all elements

$primaryLighter2: #FAE1D1;
$primaryLighter1: #F2A97A;
$primaryColor: #ED8745;
$primaryDarker1: #E86917;
$primaryDarker2: #74350C;
$maxContainerWidth: 960px;

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  color: #222;
  font-family: 'Open Sans', sans-serif;
}

body {
  background: #E16E2B;
}

h1 {
  font-size: 3em;
  text-align: center;
}
h2 {
  margin-top: 1.5em;
}

li {
  margin-top: 1.5em;
}

#header {
  position: relative;
  height: 538px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("https://images.unsplash.com/photo-1517048676732-d65bc937f952?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=1005f3d059e15847f5b8e818aafe7b51&auto=format&fit=crop&w=1024&q=80");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    .overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.85;
      // background-image: linear-gradient(176deg, #f2a97a -50%, #e86917 150%);
      background-image: linear-gradient(-135deg, rgba(255,100,0,0.90) 0%, rgba(232,105,23,0.84) 100%);
    }
  }

  #header-navbar {
    position: relative;
    top: 24px;

    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    #header-logo {
      // margin-left: -16px; // TMP until i fix the logo
      margin-top: 0;
      float: left;
      font-size: 2em;
    }

    ul#navbar-menu  {
      float: right;
      margin-top: 6px;

      li {
        float: left;
        margin-top: 0px;
        margin-right: 20px;
        list-style: none;
        font-size: 18px;
        font-weight: normal;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  h1 {
    position: relative;
    margin-top: 155px;
    margin-bottom: 0;
    color: $primaryLighter2;
    text-align: left;
    text-shadow: 2px 2px 5px rgba(0,0,0,0.30);
    line-height: 135%;
  }

  #h232 {
    font-size: 60px;
    font-family: Helvetica-Bold;
    font-size: 61px;
    text-align: center;
  }
  #consulting {
    font-weight: lighter;
    font-size: 86px;
    font-family: Helvetica-Light;
    letter-spacing: 3.84px;
    text-align: center;
    line-height: 98px;
  }
}

.container {
  max-width: $maxContainerWidth;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 3em;
}

#about-us {
  background: #311301;
  padding-top: 80px;
  padding-bottom: 80px;
  .container {
    padding-bottom: 0;
  }
  p {
    margin: 0;
    font-size: 32px;
    color: #FFF7F2;
    letter-spacing: 0;
    text-align: center;
    line-height: 48px;
    font-weight: 300;
  }
}

#usluge {

}

.content-item {
  .content-item-title {
    font-size: 48px;
    color: #FFFFFF;
    text-shadow: 0 2px 4px rgba(173,173,173,0.50);
    text-align: center;
    margin-bottom: 64px;
  }

  .content-item-body {
    * { color: #fff; }
    p {
      font-size: 24px;
      line-height: 1.5em;
      text-align: justify;
    }
    &.left-align {
      p {
        text-align: left;
      }
    }
  }
}

#usluge-list {
  li  {
    display: flex;
    list-style: none;
    margin-bottom: 80px;

    img {
      display: inline-block;
      vertical-align: middle;
    }
    .text {
      align-self: center;
      margin-left: 1em;
    }

    span {
      font-size: 24px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
}

#kontakt {
  padding-top: 24px;
  background: #311301;
  * {
    color: #FFF7F2;
  }
  h2 {
    text-align: center;
    font-size: 48px;
    margin-top: 24px;
  }
  p {
    text-align: center;
    line-height: 1.5em;
  }
}

























